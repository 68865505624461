/*
 * @Author: wangqs 
 * @description 还款主页
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2021-09-22 18:04:28
 */
<template>
  <div class="page-box">
    <div class="top-box box">
      <div
        class="bg-box box"
        :style="`background: url(${VUE_APP_STATIC_URL}repay-bg.png) center center no-repeat;background-size: cover;`"
      ></div>
      <div class="cotent-box box">
        <div
          class="text-a"
          @click="goHistory"
        >
          历史还款
        </div>
        <div class="text-b">
          本月还款金额（元）
        </div>
        <div class="text-c">
          <div class="text-item">
            {{ parseFloat(amount).toFixed(2) }}
            <img
              v-show="overdue == 1"
              src="@assets/img/repay-icon.png"
            />
          </div>
        </div>
        <!-- <van-button
          class="repay-btn"
          color="#51B464"
          round
          type="info"
        >
          立即还款
        </van-button> -->
        <div
          class="text-d"
          @click="$router.push('/user/waitRepay')"
        >
          全部待还
        </div>
      </div>
    </div>

    <!-- 订单列表 -->
    <div class="list-box box">
      <template v-if="list && list.length > 0">
        <repay-item
          ref="repayItem"
          :list-data="list"
          @getAmount="getAmount"
        ></repay-item>
      </template>
    </div>

    <!-- 按钮 -->
    <!-- <div class="btn-box box">
      <van-button
        class="btn"
        color="#51B464"
        plain
        type="primary"
      >
        还款选中部分
      </van-button>
      <van-button
        class="btn"
        color="#51B464"
        type="info"
        @click="repayBtnAll"
      >
        还款全部
      </van-button>
    </div> -->
    <van-submit-bar
      :price="selectObj.amount * 100"
      button-text="去还款"
      button-color="#51B464"
      @submit="onSubmit"
    >
      <van-checkbox
        v-model="selectObj.selectAll"
        @change="radioChange"
      >
        全选
      </van-checkbox>
    </van-submit-bar>
  </div>
</template>

<script>
const { VUE_APP_STATIC_URL } = process.env
import RepayItem from './components/repay-item.vue'
export default {
  name: 'Repayment',
  components: {
    RepayItem
  },
  data () {
    return {
      VUE_APP_STATIC_URL,
      hideDetail: true,
      amount: 0,
      overdue: '',
      list: [],
      radio: '',
      selectObj: {
        amount: 0,
        selectAll: false,
        arr: []
      }
    }
  },
  computed: {
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      let res =  await this.$http.repayApi.repayBill({})
      if (res.code === 200) {
        this.amount = res.msg.amount || 0
        this.overdue = res.msg.overdue || 0
        let list = res.msg.repaymentPlanList || []
        for (let i of list) {
          this.$set(i, 'show', false)
        }
        this.list = list
      }
    },
    goHistory () {
      this.$router.push('/user/repayHistory')
    },
    repayBtnAll () {
      this.$router.push('/user/allRepay?type=wait&amount=' + this.selectObj.amount)
    },
    onSubmit () {
      if (this.selectObj.arr && this.selectObj.arr.length > 0) {
        let repaymentIds = []
        let repayWay = '2'
        for (let i of this.selectObj.arr) {
          repaymentIds.push(i.id)
          if (i.repayWay == '1') {
            repayWay = '1'
          }
        }
        window.localStorage.setItem('repaymentIds', JSON.stringify(repaymentIds))
        this.$router.push('/user/allRepay?type=all&amount=' + this.selectObj.amount + '&repayWay=' + repayWay)
      } else {
        this.$toast.fail('请选择订单')
      }
    },
    radioChange (e) {
      if (e === true) {
        this.$refs.repayItem.selectAll()
      } else if (e === false) {
        this.$refs.repayItem.clearSelect()
      }
    },
    radioClick (e) {
      console.log(e)
    },
    getAmount (data) {
      this.selectObj.arr = data.arr
      this.selectObj.amount = data.amount
      data.arr.length == this.list.length ? this.selectObj.selectAll = true : ''
      data.arr.length == 0 ? this.selectObj.selectAll = false : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-bottom: 60px;
  .top-box {
    height: 230px;
    position: relative;
    background-color: #f5f5f5;
    .bg-box {
      height: 158px;
      width: 100%;
    }
    .cotent-box {
      height: 138px;
      width: 336px;
      position: absolute;
      left: 50%;
      top: 80px;
      margin-left: -168px;
      z-index: 9;
      background: #fff;
      border-radius: 5px;
      padding: 0 10px;
      .repay-btn {
        width: 136px;
        height: 33px;
      }
      .text-a {
        font-size: 13px;
        line-height: 26px;
      }
      .text-b {
        font-size: 12px;
        color: #646464;
        text-align: center;
        margin-top: 12px;
      }
      .text-c {
        text-align: center;
        font-size: 24px;
        line-height: 38px;
        margin-top: 10px;
        .text-item {
          display: inline-block;
          position: relative;
          img {
            width: 46px;
            height: 18px;
            position: absolute;
            right: -50px;
            top: 10px;
          }
        }
      }
      .repay-btn {
        margin: 28px auto 0;
        display: block;
      }
      .text-d {
        color: #51B464;
        text-align: right;
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }

  // list
  .list-box {
    background-color: #f5f5f5;
  }
  .btn-box {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 6px;
    text-align: center;
    background: #fff;
    z-index: 9;
    .btn {
      width: 166px;
      margin: 0 5px;
    }
  }
}
</style>
